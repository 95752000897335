<template>
  <div>
    <navi-gation />
    <div class="box" style="padding: 5px 5px 5px 15px">
      <el-row :gutter="4" class="mt15" style="display:flex;margin-top: 6px">
        <el-col :span="3">
          <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.startTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <span style="line-height: 30px; color: #dcdfe6">~</span>
        </el-col>
        <el-col :span="3">
          <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.endTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-input size="small" v-model.trim="queryData.input" :placeholder="$t('auditor.AlarmLog.placeholder')" @keyup.enter.native="onSubmit"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary"  size="small" @click="onSubmit">{{$t('public.Inquire')}}</el-button>
        </el-col>
      </el-row>
      <div class="button" style="margin-top: 10px">
        <el-button-group>
          <el-button type="primary" @click="resetForm()" size="small" style="margin-right: 0.3px">
            {{$t('public.Derive')}}
          </el-button>
        </el-button-group>
      </div>
      <div class="content">
        <el-row style="margin-top: 10px">
          <el-table size="mini" stripe ref="multipleTable" :data="tableData" class="sort_table" tooltip-effect="dark" style="width: 100%; overflow: auto" :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }" :default-sort="{
              prop: 'createtime',
              order: 'descending',
            }" @sort-change="sort">
            <!-- sortable="custom" -->
            <el-table-column prop="devicename" :show-overflow-tooltip="true" :label="$t('public.DeviceName')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.devicename }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="loginname" :label="$t('public.Username')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.loginname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="username" :label="$t('public.Name')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.username }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="groupfullname" :label="$t('public.Section')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.groupfullname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="action" :label="$t('auditor.AlarmLog.AlarmInList.AlarmContent')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.action }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="ipaddress" :show-overflow-tooltip="true" :label="$t('auditor.AlarmLog.AlarmInList.IPAddress')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.ipaddress }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="memo" :label="$t('auditor.AlarmLog.AlarmInList.remark')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.memo }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="createtime" :show-overflow-tooltip="true" :label="$t('auditor.AlarmLog.AlarmInList.time')">
              <template slot-scope="scope">
                <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                  {{ scope.row.createtime | dateformat }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <pagination :page="queryData.currentPage" :limit="queryData.pageSize" :total="queryData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        </el-row>
      </div>

    </div>
    <!-- 提示消息框 -->
    <dialogInfo :dialogInfo="messageFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import naviGation from '@/components/hearder/index' //头部组件
import pagination from '@/components/pagination/page.vue' //分页
import dialogInfo from '@/components/promptMessage/index' //提示消息框
export default {
  // 组件
  components: {
    pagination, //分页
    naviGation, //头部
    dialogInfo //提示消息
  },
  data() {
    return {
      messageFlag: false, //提示小时默认FALSE
      titleInfo: '', //提示消息
      currentPage: '', //总页数
      // 用户统计
      userData: [],
      // 管理统计
      mangerData: [],
      // 关联弹框默认不展示
      visibaelFlag: false,
      // 日历时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
       
     
      value: '',
       
      valuecx2: '',
      // 列表数据
      tableData: [],
      formInline: {
        // 选择日期
        startTime: '',
        endTime: ''
      },
      // 列表传参
      queryData: {
        //查询条件 （param）
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orderColume: 'createtime',
        orderRule: 'DESC',
        input: '',
        module: '系统管理',
        level: ''
      },
      input: '' //查询该字段
    }
  },
  created() {},
  mounted() {
    this.formInline.endTime = this.$moment().format('YYYY-MM-DD')
    this.formInline.startTime = this.$moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD')

    this.dataList() //列表
  },
  methods: {
    // 查询
    onSubmit() {
      if (this.queryData.input.length > 0) {
        this.queryData.currentPage = 1
      }
      this.dataList()
    },
    async dataList() {
      if (this.formInline.startTime == null) {
        // this.titleInfo = '请选择开始时间'
        // this.messageFlag = true
        this.$message({
          message: '请选择开始时间',
          type: 'error',
          offset: 100
        })
      } else if (this.formInline.endTime == null) {
        // this.titleInfo = '请选择结束时间'
        // this.messageFlag = true
        this.$message({
          message: '请选择结束时间',
          type: 'error',
          offset: 100
        })
      } else if (this.formInline.startTime > this.formInline.endTime) {
        // this.titleInfo = '开始时间不能大于结束时间'
        // this.messageFlag = true
        this.$message({
          message: '开始时间不能大于结束时间',
          type: 'error',
          offset: 100
        })
      } else {
        var param = {
          currentPage: this.queryData.currentPage,
          pageSize: this.queryData.pageSize,
          orderColume: this.queryData.orderColume,
          orderRule: this.queryData.orderRule,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          deviceName: this.queryData.input,
          module: '' //this.queryData.module ,先默认不传因为只有日志管理有值
        }
        const res = await this.$axios.post(
          '/httpServe/emmLogs/getAlertDataInfo',
          param,
          true
        )
        this.tableData = res.data.content
        this.currentPage = res.data.pages
        this.queryData.total = res.data.total
      }
    },
    // 排序
    sort(column) {
      if (column.order === 'ascending') {
        this.queryData.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.queryData.orderRule = 'DESC'
      }
      this.queryData.orderColume = column.prop
      this.dataList() //再次调用表格
    },
    // 分页
    handleParentGetList(page, limit) {
      this.queryData.currentPage = page
      this.queryData.pageSize = limit
      // 调用列表
      this.dataList()
    },
    // 弹框点击关闭事件
    handleClose() {
      this.messageFlag = false //提示消息关闭
    },
    //确定的关闭
    determine() {
      this.messageFlag = false
    },
    // 导出
    async resetForm() {
      let token2 = sessionStorage.getItem('Authorization')
        ? sessionStorage.getItem('Authorization')
        : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA'

      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      // clientType 1管理日志 2终端日志 3告警日志
      this.userId = JSON.parse(userString).userId
      var param = {
        currentPage: this.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        deviceName: this.queryData.input,
        clientType: 3,
        module: '',
        groupId: this.id,
        userId: this.userId
      }

      const res = await this.$axios.postExport(
        '/httpExport/emmLogs/exportExcel',
        param,
        false,
        this.userId
      )
      const link = document.createElement('a')
      var fileName = 'Logs_' + Date.parse(new Date()) + '.xls'
      let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
